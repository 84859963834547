import React, { useContext } from 'react'

export function loadEnvironmentVariables(): EnvironmentContextValues {
  return {
    googlePlaceAPIKey: process.env.GOOGLE_PLACE_API_KEY || ''
  }
}

export type EnvironmentContextValues = {
  googlePlaceAPIKey: string
}

export const EnvironmentContext = React.createContext<EnvironmentContextValues>({
  googlePlaceAPIKey: ''
})

export const useEnvironmentContext = () => {
  return useContext(EnvironmentContext)
}

const EnvironmentProvider = ({
  children,
  environmentVariables
}: {
  children: React.ReactNode
  environmentVariables: EnvironmentContextValues
}) => {
  const value: EnvironmentContextValues = {
    googlePlaceAPIKey: environmentVariables.googlePlaceAPIKey
  }

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
}

export default EnvironmentProvider
